// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import '../styles.scss'
import {graphql, Link} from 'gatsby'
import ReviewCard from '../components/reviewCard'

function getReviewSection(data) {
  return <section>
    <div className='row text-md-center mb-3'>
      <h2>Reviews</h2>
    </div>
    <div className="row justify-content-center">
      {
        data.allMdx.nodes.slice(0, 4).map((node) => {
          return (
            <div className="col-md-6">
              <ReviewCard key={node.id} node={node}/>
            </div>
          )
        })
      }
    </div>
    <div className='page-max-container text-md-center mb-3'>
      <Link to={'/reviews'} className={'text-decoration-none'}>More reviews</Link>
    </div>
  </section>;
}

// Step 2: Define your component
const IndexPage = ({data}) => {
  return (
    <Layout
      pageTitle="Welcome"
      pageSummary={"This is a place where I house my thoughts on animated cinema"}
    >
      <h2> This is a work in progress site! </h2>
      {getReviewSection(data)}
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 3) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        slug
      }
    }
  }
`;

export default IndexPage